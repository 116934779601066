<template>
  <a-spin :spinning="loadingState" :delay="250">
    <div class="edit-header">
      <div class="title">
        <div class="action-button users" @click="goBack">
          <img src="@/assets/actions/back-black.svg" alt="Ir a Inicio" />
        </div>
        <h1 class="global-title">Editar Evento</h1>
      </div>
      <a-popconfirm
        placement="leftTop"
        title="¿Está seguro de que desea eliminar este evento?"
        ok-text="Sí, eliminar evento"
        cancel-text="No, mantener evento"
        @confirm="deleteEvent"
      >
        <div class="action-button delete">
          <img src="@/assets/actions/delete-icon.svg" alt="Eliminar Evento" />
        </div>
      </a-popconfirm>
    </div>
    <div class="splitted">
      <Form v-model="eventData" @publish-event="editEvent" />
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Form from "@/components/events/EditForm.vue";
import { Navigation, Store } from "@/controllers/common";
import CCEvent from "@/models/event";
import EventsController from "@/controllers/events";

import Authentication from "@/controllers/auth";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "EditUser",
  setup() {
    useMeta({
      title: "Editar Evento",
      description: "Edita uno de los eventos existentes.",
    });
  },
  components: {
    Form,
  },
  created() {
    var event = Store.getValue("edition/event") as CCEvent;
    if (event) {
      this.eventData = event;
    } else {
      Navigation.goTo("Events");
    }
  },
  beforeUnmount() {
    Store.update("edition/storeEvent", undefined);
  },
  data() {
    return {
      loadingState: false,
      eventData: {
        days: {
          L: false,
          M: false,
          X: false,
          J: false,
          V: false,
          S: false,
          D: false,
        },
        distribution: {
          faceToFace: false,
          streamed: false,
        },
      } as CCEvent,
    };
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async deleteEvent(): Promise<void> {
      this.loadingState = true;
      await EventsController.deleteEvent(
        this.eventData.reference,
        this.eventData.type,
        this.currentUser
      );
      this.loadingState = false;
    },
    async editEvent(): Promise<void> {
      this.loadingState = true;
      await EventsController.updateEvent(
        this.eventData,
        this.eventData.type,
        this.currentUser
      );
      this.loadingState = false;
    },
  },
});
</script>

<style scoped>
@media only screen and (min-width: 768px) {
  .edit-header {
    width: calc(50% - 2rem);
  }
}
</style>
