export type PickerType = "date" | "time" | "dateTime";

export enum EventCategory {
  weekly = "weekly",
  special = "special",
}

export interface EventDistribution extends Record<string, boolean> {
  faceToFace: boolean;
  streamed: boolean;
}

export interface WeeklyEventData {
  name: string;
  days: WeekData;
  starts: string;
  ends: string;
  distribution: EventDistribution;
  reference: string;
}

export interface WeekData extends Record<string, boolean> {
  L: boolean;
  M: boolean;
  X: boolean;
  J: boolean;
  V: boolean;
  S: boolean;
  D: boolean;
}

export interface SpecialEventData {
  name: string;
  starts: string;
  ends: string;
  distribution: EventDistribution;
  reference: string;
}

export default class CCEvent {
  name: string;
  days: WeekData = {} as WeekData;
  starts: string;
  ends: string;
  distribution: EventDistribution;
  type: EventCategory;
  reference: string;

  constructor(
    { name, days, starts, ends, distribution }: WeeklyEventData,
    type: EventCategory
  );

  constructor(
    { name, starts, ends, distribution }: SpecialEventData,
    type: EventCategory
  );

  constructor(data: WeeklyEventData | SpecialEventData, type: EventCategory) {
    this.name = data.name;
    this.starts = data.starts;
    this.ends = data.ends;
    this.distribution = data.distribution ?? {
      faceToFace: false,
      streamed: false,
    };
    this.type = type;
    this.reference = data.reference;
    if (type === EventCategory.weekly) {
      const weekly = data as WeeklyEventData;
      this.days = weekly.days;
    }
  }

  toWeeklyInterface(): WeeklyEventData {
    return {
      name: this.name,
      days: this.days,
      starts: this.starts,
      ends: this.ends,
      distribution: this.distribution,
      reference: this.reference,
    };
  }

  toSpecialInterface(): SpecialEventData {
    return {
      name: this.name,
      starts: this.starts,
      ends: this.ends,
      distribution: this.distribution,
      reference: this.reference,
    };
  }

  private static capitalize(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  static formatTime(date: Date): string {
    const formatter = new Intl.DateTimeFormat("es-CO", {
      timeStyle: "short",
    } as Intl.DateTimeFormatOptions);
    const formatedTime = formatter.format(date);
    return formatedTime.replace("a. m.", "AM").replace("p. m.", "PM");
  }

  static formatDate(date: Date): string {
    const formatter = new Intl.DateTimeFormat("es-CO", {
      dateStyle: "long",
    } as Intl.DateTimeFormatOptions);
    const formatedTime = formatter.format(date).split(" de ");
    return `${this.capitalize(formatedTime[1])} ${formatedTime[0]},
     ${formatedTime[2]}`;
  }
}
