<template>
  <div class="checkbox-group columns all">
    <label class="field-label">{{ label }}</label>
    <div class="group" :class="{ 'multi-row': multipleRows }">
      <BaseCheckbox
        v-model="value[name]"
        :label="options[index]"
        v-for="(state, name, index) in value"
        :key="index"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseCheckbox from "@/components/common/BaseCheckbox.vue";

export default defineComponent({
  name: "BaseCheckboxGroup",
  components: {
    BaseCheckbox,
  },
  props: {
    modelValue: {
      type: Object as PropType<Record<string, boolean>>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    multipleRows: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get(): Record<string, boolean> {
        return this.modelValue;
      },
      set(value: Record<string, boolean>): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style scoped>
.checkbox-group {
  font-size: 1rem;
  width: 100%;
}
.checkbox-group .field-label {
  font-size: 1.6em;
  font-weight: var(--f-semibold);
  margin-bottom: 0.3em;
  display: block;
}

.group {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
}

.group.multi-row {
  flex-wrap: wrap;
}

.group.multi-row > .check-box {
  width: calc(50% - 0.5rem);
}

@media only screen and (min-width: 768px) {
  .checkbox-group .field-label {
    margin-bottom: 6px;
  }

  .group.multi-row > .check-box {
    width: calc(25% - 0.75rem);
  }
}
</style>
