
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";
import WeekArray from "@/components/events/weekly/WeekArray.vue";

import CalendarEvent, {
  EventCategory,
  SpecialEventData,
  WeeklyEventData,
} from "@/models/event";

export default defineComponent({
  name: "UserCard",
  components: {
    BaseCard,
    WeekArray,
  },
  props: {
    data: {
      type: Object as PropType<WeeklyEventData | SpecialEventData>,
      required: true,
    },
    eventType: {
      type: String as PropType<EventCategory>,
      required: true,
    },
  },
  computed: {
    isSpecial(): boolean {
      return this.eventType === EventCategory.special;
    },
    date(): string {
      var startDate = new Date(this.data.starts);
      var endDate = new Date(this.data.ends);
      return `${CalendarEvent.formatDate(startDate)}
       - ${CalendarEvent.formatDate(endDate)}`;
    },
    time(): string {
      var startTime = new Date(this.data.starts);
      var endTime = new Date(this.data.ends);
      return `${CalendarEvent.formatTime(startTime)}
       - ${CalendarEvent.formatTime(endTime)}`;
    },
  },
});
