<template>
  <div class="events-cnt">
    <div class="events-title">
      <h1 class="global-title">Eventos Semanales</h1>
      <BaseButton :isSmall="true" text="Agregar" @click="addWeeklyEvent" />
    </div>
    <a-spin :spinning="fetchingWeekly">
      <div class="events-section" v-if="!showWeeklyError">
        <div class="events weekly animated-container">
          <EventRecord
            :data="event.toWeeklyInterface()"
            :eventType="event.type"
            v-for="(event, index) in weeklyEvents"
            :key="index"
            @click="editEvent(event)"
          />
        </div>
      </div>
      <BaseError message="No se encontraron eventos." v-if="showWeeklyError" />
    </a-spin>
    <div class="events-title">
      <h1 class="global-title">Eventos Especiales</h1>
      <BaseButton :isSmall="true" text="Agregar" @click="addSpecialEvent" />
    </div>
    <a-spin :spinning="fetchingSpecial">
      <div class="events-section" v-if="!showSpecialError">
        <div class="events special animated-container">
          <EventRecord
            :data="event.toSpecialInterface()"
            :eventType="event.type"
            v-for="(event, index) in specialEvents"
            :key="index"
            @click="editEvent(event)"
          />
        </div>
      </div>
      <BaseError message="No se encontraron eventos." v-if="showSpecialError" />
    </a-spin>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Navigation, Store } from "@/controllers/common";

import BaseButton from "@/components/common/BaseButton.vue";
import EventRecord from "@/components/events/EventRecord.vue";
import CCEvent, {
  EventCategory,
  SpecialEventData,
  WeeklyEventData,
} from "@/models/event";
import BaseError from "@/components/common/BaseError.vue";
import Authentication from "@/controllers/auth";

import EventsController from "@/controllers/events";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Events",
  setup() {
    useMeta({
      title: "Eventos",
      description: "Accede a la lista de eventos.",
    });
  },
  components: {
    BaseButton,
    EventRecord,
    BaseError,
  },
  created() {
    if (this.currentUser) {
      this.fetchingWeekly = true;
      this.fetchingSpecial = true;
      EventsController.fecthAll<WeeklyEventData>(
        EventCategory.weekly,
        this.currentUser
      ).then((eventsData) => {
        this.fetchingWeekly = false;
        if (eventsData) {
          const data = eventsData as WeeklyEventData[];
          let events: CCEvent[] = [];
          for (const event of data) {
            events.push(new CCEvent(event, EventCategory.weekly));
          }
          this.weeklyEvents = events;
        }
      });
      EventsController.fecthAll<SpecialEventData>(
        EventCategory.special,
        this.currentUser
      ).then((eventsData) => {
        this.fetchingSpecial = false;
        if (eventsData) {
          const data = eventsData as SpecialEventData[];
          let events: CCEvent[] = [];
          for (const event of data) {
            events.push(new CCEvent(event, EventCategory.special));
          }
          this.specialEvents = events;
        }
      });
    }
  },
  data() {
    return {
      fetchingWeekly: false,
      fetchingSpecial: false,
      weeklyEvents: [] as Array<CCEvent>,
      specialEvents: [] as Array<CCEvent>,
    };
  },
  methods: {
    addWeeklyEvent(): void {
      Navigation.goTo("AddWeeklyEvent");
    },
    addSpecialEvent(): void {
      Navigation.goTo("AddSpecialEvent");
    },
    editEvent(event: CCEvent): void {
      Store.update("edition/storeEvent", event);
      Navigation.goTo("EditEvent");
    },
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    showWeeklyError(): boolean {
      return this.weeklyEvents.length == 0 && !this.fetchingWeekly;
      // return false;
    },
    showSpecialError(): boolean {
      return this.specialEvents.length == 0 && !this.fetchingSpecial;
      // return false;
    },
  },
});
</script>

<style scoped>
.events-section,
.error-cnt {
  min-height: 35vh;
}

.events-cnt {
  display: grid;
}

.events-title {
  column-gap: 1rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.events {
  row-gap: 2rem;
  display: grid;
}

@media only screen and (min-width: 768px) {
  .events {
    column-gap: 1.2rem;
    row-gap: 1.2rem;
    grid-template-columns: repeat(2, calc(50% - 0.6rem));
  }
}

@media only screen and (min-width: 1280px) {
  .events-cnt {
    min-height: var(--container-min-height);
    grid-template-rows: min-content auto min-content auto;
  }

  .events {
    column-gap: 3rem;
    row-gap: 3rem;
    grid-template-columns: repeat(3, calc(33.33% - 2rem));
  }
}

@media only screen and (min-width: 1800px) {
  .events {
    column-gap: 3rem;
    row-gap: 3rem;
    grid-template-columns: repeat(4, calc(25% - 2.25rem));
  }
}
</style>
