
import { WeekData } from "@/models/event";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "WeekArray",
  props: {
    week: {
      type: Object as PropType<WeekData>,
    },
  },
});
