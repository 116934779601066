
import { defineComponent } from "vue";
import Form from "@/components/events/EditForm.vue";
import { Navigation, Store } from "@/controllers/common";
import CCEvent from "@/models/event";
import EventsController from "@/controllers/events";

import Authentication from "@/controllers/auth";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "EditUser",
  setup() {
    useMeta({
      title: "Editar Evento",
      description: "Edita uno de los eventos existentes.",
    });
  },
  components: {
    Form,
  },
  created() {
    var event = Store.getValue("edition/event") as CCEvent;
    if (event) {
      this.eventData = event;
    } else {
      Navigation.goTo("Events");
    }
  },
  beforeUnmount() {
    Store.update("edition/storeEvent", undefined);
  },
  data() {
    return {
      loadingState: false,
      eventData: {
        days: {
          L: false,
          M: false,
          X: false,
          J: false,
          V: false,
          S: false,
          D: false,
        },
        distribution: {
          faceToFace: false,
          streamed: false,
        },
      } as CCEvent,
    };
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async deleteEvent(): Promise<void> {
      this.loadingState = true;
      await EventsController.deleteEvent(
        this.eventData.reference,
        this.eventData.type,
        this.currentUser
      );
      this.loadingState = false;
    },
    async editEvent(): Promise<void> {
      this.loadingState = true;
      await EventsController.updateEvent(
        this.eventData,
        this.eventData.type,
        this.currentUser
      );
      this.loadingState = false;
    },
  },
});
