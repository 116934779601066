<template>
  <div class="week-array">
    <div
      class="week-day"
      :class="{ active: value }"
      v-for="(value, name, index) in week"
      :key="index"
    >
      <span class="day">{{ name }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { WeekData } from "@/models/event";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "WeekArray",
  props: {
    week: {
      type: Object as PropType<WeekData>,
    },
  },
});
</script>

<style scoped>
.week-array {
  margin: 0.8rem 0;
  column-gap: 1.2rem;
  justify-content: center;
  display: flex;
}

.week-day {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 0.1rem;
  background: #ebebeb;
  justify-content: center;
  align-items: center;
  display: flex;
}

.week-day.active {
  background: var(--c-harvest-gold);
}

.week-array .day {
  font-size: 1.4rem;
  text-align: center;
  font-weight: var(--f-semibold);
  color: #fff;
}
</style>
