<template>
  <BaseCard :actionable="true">
    <div class="event-data">
      <div class="event-content">
        <h3 class="title">{{ data.name }}</h3>
        <WeekArray :week="data.days" v-if="!isSpecial" />
        <h3 class="date">{{ date }}</h3>
      </div>
      <div class="time-type">
        <h3 class="time">{{ time }}</h3>
        <div class="evt-type">
          <img
            src="@/assets/events/face-to-face.svg"
            alt="Presencial"
            class="type face-to-face"
            v-show="data.distribution.faceToFace"
          />
          <img
            src="@/assets/events/streamed.svg"
            alt="Transmisión"
            class="type streamed"
            v-show="data.distribution.streamed"
          />
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";
import WeekArray from "@/components/events/weekly/WeekArray.vue";

import CalendarEvent, {
  EventCategory,
  SpecialEventData,
  WeeklyEventData,
} from "@/models/event";

export default defineComponent({
  name: "UserCard",
  components: {
    BaseCard,
    WeekArray,
  },
  props: {
    data: {
      type: Object as PropType<WeeklyEventData | SpecialEventData>,
      required: true,
    },
    eventType: {
      type: String as PropType<EventCategory>,
      required: true,
    },
  },
  computed: {
    isSpecial(): boolean {
      return this.eventType === EventCategory.special;
    },
    date(): string {
      var startDate = new Date(this.data.starts);
      var endDate = new Date(this.data.ends);
      return `${CalendarEvent.formatDate(startDate)}
       - ${CalendarEvent.formatDate(endDate)}`;
    },
    time(): string {
      var startTime = new Date(this.data.starts);
      var endTime = new Date(this.data.ends);
      return `${CalendarEvent.formatTime(startTime)}
       - ${CalendarEvent.formatTime(endTime)}`;
    },
  },
});
</script>

<style scoped>
.cnt-card > div {
  height: 100%;
}

.event-data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  margin: 0;
  margin-bottom: 0.8rem;
  color: var(--c-mid-gray);
  text-align: center;
  font-size: 1.5rem;
  font-weight: var(--f-semibold);
}

.date,
.time {
  margin: 0;
  color: var(--c-silver-chalice);
  font-size: 1.4rem;
  font-weight: var(--f-regular);
}

.time-type {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.evt-type {
  column-gap: 0.6rem;
  display: flex;
}

.evt-type .type {
  height: 28px;
  width: 28px;
}
</style>
