
import { defineComponent, PropType } from "vue";

import BaseCheckbox from "@/components/common/BaseCheckbox.vue";

export default defineComponent({
  name: "BaseCheckboxGroup",
  components: {
    BaseCheckbox,
  },
  props: {
    modelValue: {
      type: Object as PropType<Record<string, boolean>>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    multipleRows: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get(): Record<string, boolean> {
        return this.modelValue;
      },
      set(value: Record<string, boolean>): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
