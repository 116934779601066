<template>
  <form action="" method="post" class="cc-form">
    <BaseField
      class="all"
      v-model="value.name"
      type="text"
      label="Nombre:"
      placeholder="Nombre"
    />
    <BaseCheckboxGroup
      v-model="value.distribution"
      label="Tipo:"
      :options="types"
    />
    <BaseDateTimePicker
      :class="{
        all: respClasses.mobile,
        half: !respClasses.mobile,
      }"
      v-model="starts"
      type="dateTime"
      label="Inicio:"
    />
    <BaseDateTimePicker
      :class="{
        all: respClasses.mobile,
        half: !respClasses.mobile,
      }"
      v-model="ends"
      type="dateTime"
      label="Fin:"
    />
    <BaseButton
      text="Agregar"
      class="all columns"
      :disabled="v$.$invalid"
      @click="handleAction"
    />
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import BaseCheckboxGroup from "@/components/common/BaseCheckGroup.vue";
import BaseDateTimePicker from "@/components/common/BaseDateTimePicker.vue";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import { SpecialEventData } from "@/models/event";

export default defineComponent({
  name: "SpecialEventForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BaseButton,
    BaseField,
    BaseCheckboxGroup,
    BaseDateTimePicker,
  },
  props: {
    modelValue: {
      type: Object as PropType<SpecialEventData>,
      required: true,
    },
  },
  data() {
    return {
      starts: new Date(),
      ends: new Date(),
      types: ["Presencial", "Transmisión"],
    };
  },
  watch: {
    starts: {
      immediate: true,
      handler(): void {
        this.value.starts = this.starts.toUTCString();
      },
    },
    ends: {
      immediate: true,
      handler(): void {
        this.value.ends = this.ends.toUTCString();
      },
    },
  },
  emits: ["update:modelValue", "publishEvent"],
  computed: {
    value: {
      get(): SpecialEventData {
        return this.modelValue;
      },
      set(value: SpecialEventData): void {
        this.$emit("update:modelValue", value);
      },
    },
    respClasses(): Record<string, boolean> {
      var screen = ResponsiveManager.retrieveScreenSize();
      var obj = {
        desktop:
          screen === ScreenType.largeDesktop ||
          screen === ScreenType.smallDesktop,
        tablet: screen === ScreenType.tablet,
        mobile: screen === ScreenType.mobile,
      };
      return obj;
    },
  },
  methods: {
    handleAction(event: Event): void {
      event.preventDefault();
      this.$emit("publishEvent");
    },
    haveSelectedDistribution(): boolean {
      const methods = Object.values(this.value.distribution);
      return methods.includes(true);
    },
  },
  validations() {
    const validDateRange = () =>
      Date.parse(this.value.ends) > Date.parse(this.value.starts);
    const atLeastOneMethod = () => this.haveSelectedDistribution();
    return {
      value: {
        name: { required },
        starts: { required, validDateRange },
        ends: { required, validDateRange },
        distribution: {
          faceToFace: { atLeastOneMethod },
          streamed: { atLeastOneMethod },
        },
      },
    };
  },
});
</script>
