import {
  EventCategory,
  SpecialEventData,
  WeeklyEventData,
} from "@/models/event";
import { FirebaseUser } from "@/models/users";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Navigation } from "./common";
import ServiceController from "./service";

export default class EventsController extends ServiceController {
  static async addEvent(
    eventData: SpecialEventData | WeeklyEventData,
    type: EventCategory,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          type: type,
        },
      } as AxiosRequestConfig;
      await axios.post(
        `${EventsController.HOST}/events/add`,
        eventData,
        config
      );
      Navigation.goTo("Events");
    } catch (error) {
      const axiosError = error as AxiosError;
      EventsController.translateError(axiosError);
    }
  }

  static async updateEvent(
    eventData: SpecialEventData | WeeklyEventData,
    type: EventCategory,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          type: type,
          eventId: eventData.reference,
        },
      } as AxiosRequestConfig;
      await axios.put(
        `${EventsController.HOST}/events/update`,
        eventData,
        config
      );
      Navigation.goTo("Events");
    } catch (error) {
      const axiosError = error as AxiosError;
      EventsController.translateError(axiosError);
    }
  }

  static async deleteEvent(
    eventId: string,
    type: EventCategory,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const headers = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          type: type,
          eventId: eventId,
        },
      } as AxiosRequestConfig;
      await axios.delete(`${EventsController.HOST}/events/remove`, headers);
      Navigation.goTo("Events");
    } catch (error) {
      const axiosError = error as AxiosError;
      EventsController.translateError(axiosError);
    }
  }

  static async fecthAll<T>(
    type: EventCategory,
    user: FirebaseUser
  ): Promise<T[] | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          type: type,
        },
      } as AxiosRequestConfig;
      const response = await axios.get(
        `${EventsController.HOST}/events/admin/all`,
        config
      );
      const events: T[] = response.data.events;

      return events;
    } catch (error) {
      const axiosError = error as AxiosError;
      EventsController.translateError(axiosError);
    }
  }
}
