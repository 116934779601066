
import { defineComponent, PropType } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import BaseCheckboxGroup from "@/components/common/BaseCheckGroup.vue";
import BaseDateTimePicker from "@/components/common/BaseDateTimePicker.vue";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import CCEvent, { EventCategory } from "@/models/event";

export default defineComponent({
  name: "ProfileForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BaseButton,
    BaseField,
    BaseCheckboxGroup,
    BaseDateTimePicker,
  },
  props: {
    modelValue: {
      type: Object as PropType<CCEvent>,
      required: true,
    },
  },
  created() {
    this.starts.setTime(Date.parse(this.value.starts));
    this.ends.setTime(Date.parse(this.value.ends));
  },
  data() {
    return {
      starts: new Date(),
      ends: new Date(),
      options: [
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ],
      types: ["Presencial", "Transmisión"],
    };
  },
  watch: {
    starts: {
      handler(): void {
        this.value.starts = this.starts.toUTCString();
      },
    },
    ends: {
      handler(): void {
        this.value.ends = this.ends.toUTCString();
      },
    },
  },
  emits: ["update:modelValue", "publishEvent"],
  computed: {
    value: {
      get(): CCEvent {
        return this.modelValue;
      },
      set(value: CCEvent): void {
        this.$emit("update:modelValue", value);
      },
    },
    respClasses(): Record<string, boolean> {
      var screen = ResponsiveManager.retrieveScreenSize();
      var obj = {
        desktop:
          screen === ScreenType.largeDesktop ||
          screen === ScreenType.smallDesktop,
        tablet: screen === ScreenType.tablet,
        mobile: screen === ScreenType.mobile,
      };
      return obj;
    },
    isWeeklyEvent(): boolean {
      return this.value.type == EventCategory.weekly;
    },
  },
  methods: {
    async handleAction(event: Event): Promise<void> {
      event.preventDefault();
      this.$emit("publishEvent");
    },
    haveSelectedDays(): boolean {
      const days = Object.values(this.value.days);
      return this.isWeeklyEvent ? days.includes(true) : true;
    },
    haveSelectedDistribution(): boolean {
      const methods = Object.values(this.value.distribution);
      return methods.includes(true);
    },
    async updateEvent(event: Event): Promise<void> {
      event.preventDefault();
      this.$emit("publishEvent");
    },
  },
  validations() {
    const validDateRange = () =>
      Date.parse(this.value.ends) > Date.parse(this.value.starts);
    const atLeastOneDay = () => this.haveSelectedDays();
    const atLeastOneMethod = () => this.haveSelectedDistribution();
    return {
      value: {
        name: { required },
        starts: { required, validDateRange },
        ends: { required, validDateRange },
        days: {
          L: { atLeastOneDay },
          M: { atLeastOneDay },
          X: { atLeastOneDay },
          J: { atLeastOneDay },
          V: { atLeastOneDay },
          S: { atLeastOneDay },
          D: { atLeastOneDay },
        },
        distribution: {
          faceToFace: { atLeastOneMethod },
          streamed: { atLeastOneMethod },
        },
      },
    };
  },
});
