
import { defineComponent, PropType } from "vue";

import { DatePicker } from "v-calendar";

import { PickerType } from "@/models/event";

export default defineComponent({
  name: "BaseDateTimePicker",
  components: {
    DatePicker,
  },
  props: {
    modelValue: {
      type: Date,
      required: true,
    },
    type: {
      type: String as PropType<PickerType>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      // eslint-disable-next-line
      get(): Date {
        return this.modelValue;
      },
      set(value: Date): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
