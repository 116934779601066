<template>
  <div class="form-field columns">
    <label>{{ label }}</label>
    <DatePicker v-model="value" color="yellow" :mode="type">
      <template v-slot="{ inputValue, inputEvents }">
        <input
          class="input"
          :value="inputValue"
          v-on="inputEvents"
          type="text"
          readonly
        />
      </template>
    </DatePicker>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { DatePicker } from "v-calendar";

import { PickerType } from "@/models/event";

export default defineComponent({
  name: "BaseDateTimePicker",
  components: {
    DatePicker,
  },
  props: {
    modelValue: {
      type: Date,
      required: true,
    },
    type: {
      type: String as PropType<PickerType>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      // eslint-disable-next-line
      get(): Date {
        return this.modelValue;
      },
      set(value: Date): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style scoped>
textarea {
  resize: none;
}
.form-field {
  font-size: 1rem;
  width: 100%;
}
.form-field label {
  font-size: 1.6em;
  font-weight: var(--f-semibold);
  margin-bottom: 0.3em;
  display: block;
}
.form-field .input,
.form-field .dropdown {
  font-size: 1.4em;
  font-weight: var(--f-regular);
  font-family: "Montserrat", sans-serif;
  border-radius: 8px;
  background: var(--c-concrete);
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 1em;
  height: 4.8rem;
  width: 100%;
}
@media only screen and (min-width: 1025px) {
  .field-label {
    margin-bottom: 6px;
  }
}
</style>
