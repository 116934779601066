
import { defineComponent } from "vue";
import { Navigation, Store } from "@/controllers/common";

import BaseButton from "@/components/common/BaseButton.vue";
import EventRecord from "@/components/events/EventRecord.vue";
import CCEvent, {
  EventCategory,
  SpecialEventData,
  WeeklyEventData,
} from "@/models/event";
import BaseError from "@/components/common/BaseError.vue";
import Authentication from "@/controllers/auth";

import EventsController from "@/controllers/events";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Events",
  setup() {
    useMeta({
      title: "Eventos",
      description: "Accede a la lista de eventos.",
    });
  },
  components: {
    BaseButton,
    EventRecord,
    BaseError,
  },
  created() {
    if (this.currentUser) {
      this.fetchingWeekly = true;
      this.fetchingSpecial = true;
      EventsController.fecthAll<WeeklyEventData>(
        EventCategory.weekly,
        this.currentUser
      ).then((eventsData) => {
        this.fetchingWeekly = false;
        if (eventsData) {
          const data = eventsData as WeeklyEventData[];
          let events: CCEvent[] = [];
          for (const event of data) {
            events.push(new CCEvent(event, EventCategory.weekly));
          }
          this.weeklyEvents = events;
        }
      });
      EventsController.fecthAll<SpecialEventData>(
        EventCategory.special,
        this.currentUser
      ).then((eventsData) => {
        this.fetchingSpecial = false;
        if (eventsData) {
          const data = eventsData as SpecialEventData[];
          let events: CCEvent[] = [];
          for (const event of data) {
            events.push(new CCEvent(event, EventCategory.special));
          }
          this.specialEvents = events;
        }
      });
    }
  },
  data() {
    return {
      fetchingWeekly: false,
      fetchingSpecial: false,
      weeklyEvents: [] as Array<CCEvent>,
      specialEvents: [] as Array<CCEvent>,
    };
  },
  methods: {
    addWeeklyEvent(): void {
      Navigation.goTo("AddWeeklyEvent");
    },
    addSpecialEvent(): void {
      Navigation.goTo("AddSpecialEvent");
    },
    editEvent(event: CCEvent): void {
      Store.update("edition/storeEvent", event);
      Navigation.goTo("EditEvent");
    },
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    showWeeklyError(): boolean {
      return this.weeklyEvents.length == 0 && !this.fetchingWeekly;
      // return false;
    },
    showSpecialError(): boolean {
      return this.specialEvents.length == 0 && !this.fetchingSpecial;
      // return false;
    },
  },
});
