
import { defineComponent } from "vue";
import Form from "@/components/events/special/Form.vue";
import { Navigation } from "@/controllers/common";
import Authentication from "@/controllers/auth";

import { EventCategory, SpecialEventData } from "@/models/event";
import EventsController from "@/controllers/events";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "AddSpecialEvent",
  setup() {
    useMeta({
      title: "Agregar Evento",
      description: "Agrega un nuevo evento especial.",
    });
  },
  components: {
    Form,
  },
  data() {
    return {
      loadingState: false,
      eventData: {
        distribution: {
          faceToFace: false,
          streamed: false,
        },
      } as SpecialEventData,
    };
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async addEvent(): Promise<void> {
      this.loadingState = true;
      await EventsController.addEvent(
        this.eventData,
        EventCategory.special,
        this.currentUser
      );
      this.loadingState = false;
    },
  },
});
