<template>
  <label class="check-box">
    <input class="checkbox-custom" type="checkbox" v-model="value" hidden />
    <span class="check-toggle"></span>
    <label class="checkbox-label" for="checkbox-custom">{{ label }}</label>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "BaseCheckbox",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style scoped>
.check-box {
  font-size: 1rem;
  column-gap: 1rem;
  align-items: center;
  display: flex;
}

.check-toggle {
  --checkbox-size: 2rem;
}

.checkbox-custom ~ .check-toggle {
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: 0.1rem solid #969696;
  border-radius: 50%;
  cursor: pointer;
}

.checkbox-custom:hover ~ .check-toggle {
  border-color: #4a4a4a;
}

.checkbox-custom:focus ~ .check-toggle {
  border-color: #b0d5ff;
  box-shadow: 0 0 0 2px rgba(23, 133, 255, 0.25);
}

.checkbox-custom:checked ~ .check-toggle {
  border-color: var(--c-harvest-gold);
  background: var(--c-harvest-gold)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)
    center no-repeat;
  background-size: 75%;
}

.check-box .checkbox-label {
  font-size: 1.6rem;
  font-weight: var(--f-regular);
}
</style>
